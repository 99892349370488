<template>
  <div class="container">
    <Index ref="indexRef" />
</div>
</template>

<script>
import Index from "./view/index.vue";

import aos from "@/assets/js/aos.js";

aos.init({
  offset: 200,
  duration: 1000,
  easing: "ease-in-sine",
  delay: 100,
  // disable: "mobile",
  once: true,
});

export default {
  name: "App",
  components: {
    Index
  },
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
    handleH5(type, url) {
      this.$refs.indexRef.handleH5(type, url)
    }
  },
};
</script>

<style lang="scss">
@import "~@/assets/styles/variable.scss";
@import "~@/assets/css/animate.min.css";
@import "~@/assets/css/aos.css";

* {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

#app {
  font-family: Source Han Sans CN;
  font-style: normal;
  font-weight: 500;
  width: 100%;
  color: #1C1C1C;
}
div {
  box-sizing: border-box;
}
</style>
