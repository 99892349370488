<template>
  <div class="container">
    <div class="header">
      <div class="logo">
        <img src="@/assets/logo.png" />
      </div>
      <div class="banner">
        <div class="banner-text">
          <div class="banner-text-title">
            <span>DeFi Liquidity Mining</span>
          </div>
          <div class="banner-text-label">
            <span>Comparison between Liquidity Mining and Staking Mining</span>
          </div>
          <div class="banner-text-content">
            <div class="banner-text-content-item">
              <img src="@/assets/images/img_pc_flexible.png" />
              <span>More flexible (can withdraw money at any time)</span>
            </div>
            <div class="banner-text-content-item">
              <img src="@/assets/images/img_pc_Safety.png" />
              <span>safer. Staking mining is to put your assets on the platform, and liquidity mining is to put your
                assets in your own decentralized wallet.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-one">
        <div class="table">
          <div class="table-title">
            <img src="@/assets/images/table-logo.png" />
            <span class="logo-title">return on</span>&nbsp;investment
          </div>
          <div class="table-header">
            <div class="table-th">Invest（USDT）</div>
            <div class="table-th">Daily USDT asset investment return</div>
            <div class="table-th">Daily income</div>
          </div>
          <div class="table-body">
            <div class="table-tr" v-for="tableItem in tableList" :key="tableItem.value">
              <div class="table-td">{{ tableItem.invest }}</div>
              <div class="table-td">{{ tableItem.bili }}</div>
              <div class="table-td">{{ tableItem.daily }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-two">
        <img src="@/assets/images/pc/icon_pc_about.png" />
        <div class="about-text">
          <div class="about-text-title">
            About&nbsp;<span>DeFi</span>
          </div>
          <div class="about-text-content">
            <p>DeFi is one of the many emerging concepts emerging today, and DeFi liquidity mining (also known as yield
              farming) is one of them.</p>

            <p>It is a key feature of DeFi, which allows people to earn rewards by depositing (or "staking")
              acryptocurrency or token on a DEX or DApp,
              These platforms reward users with another token that can later be staked on the same or another DEX or
              DApp
              without limit.Even more attractive is the fact that each cryptocurrency or token earns a yield (like
              interest on savings),And you can obtain (that is, "dig") higher returns through the cumulative staking
              process.</p>

            <p>DeFi liquidity mining subverts the traditional mining model of the past.
              Physical mining uses computing power and energy to exchange tokens for rewards,
              Liquidity mining is a form of "coin-to-coin". Just deposit tokens (trust wallet) and connect to the mining
              pool to achieve smart mining and income generation!</p>
          </div>
        </div>
      </div>
      <div class="content-three">
        <div class="content-three-title">
          How to&nbsp;<span>participate</span>
        </div>
        <div class="content-three-content">
          <p>There are two ways to download Trust Wallet: (Binance acquired decentralized wallet-Trust Wallet in 2018)
          </p>
          <p>1: Download directly from Trust Wallet official website: <a href="https://trustwallet.com/" target="_blank">https://trustwallet.com/</a></p>
          <p>2: Search on Google Store or Apple Store: Trust Wallet</p>
		  <p>If you want to know more, <a href="https://chat.whatsapp.com/DOdadaYJpq15cAJq6J07yd" target="_blank">add the mining community group</a></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "app-header",
  props: {},
  data() {
    return {
      tableList: [
        {
          invest: "500~1499",
          bili: "0.1%",
          daily: "5~14.99",
        },
        {
          invest: "1500~2999",
          bili: "1.5%",
          daily: "15~44.98",
        }, {
          invest: "3000~4999",
          bili: "2.0%",
          daily: "60~99.98",
        }, {
          invest: "5000~9999",
          bili: "3.0%",
          daily: "150~299.97",
        }, {
          invest: "10000~49999",
          bili: "5.0%",
          daily: "500~2499.95",
        }, {
          invest: "50000~99999",
          bili: "8.0%",
          daily: "4000~7999.92",
        }, {
          invest: "100000 USDT or above",
          bili: "12.0%",
          daily: "12,000 USDT or above",
        },
      ],
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.container {
  width: 100% !important;
  min-width: 390px;

  .header {
    width: 100%;
    height: 600px;
    padding: 45px 260px;
    background-image: url("~@/assets/images/pc/img_pc_bg.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #000000;

    .logo {
      width: 148px;
      height: 40px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .banner {
      margin-top: 95px;

      &-text {
        &-title {
          width: 653px;
          height: 60px;
          font-family: Aharoni-Bold;
          font-weight: 700;
          font-size: 60px;
          color: #F1BA0B;
          letter-spacing: 0;
          // // text-align: justify
        }

        &-label {
          margin: 50px 0 24px;
          width: 753px;
          height: 37px;
          font-family: PingFangSC-SNaNpxibold;
          font-weight: 600;
          font-size: 20px;
          color: #FFFFFF;
          letter-spacing: 0;
        }

        &-content {
          &-item {
            width: 564px;
            display: flex;
            align-items: center;

            img {
              width: 32px;
              height: 32px;
              margin-right: 16px;
            }

            .span {
              height: 49px;
              font-family: PingFangSC-Regular;
              font-size: 16px;
              color: #F1BA0B;
              letter-spacing: 0;
            }

            &:last-child {
              margin-top: 16px
            }
          }
        }
      }
    }
  }

  .content {
    padding: 13px 265px 128px;
    background: #0B0B0B;

    &-one {
      padding-top: 90px;
      background-image: url("~@/assets/images/pc/img_pc_Light.png");
      background-size: 100%;
      background-position: top center;
      background-repeat: no-repeat;

      // .table::before {
      //   content: '';
      //     width: 100%;
      //     height: 100%;
      //     position: absolute;
      //     z-index: 1;
      //     filter: blur(4px);
      // }
      .table {
        background: #ffffff1a;
        border: 1px solid #ffffff80;
        border-radius: 24px;
        padding: 0 60px 72px;
        position: relative;
        // filter: blur(4px);
        backdrop-filter: blur(4px);
          // backdrop-filter: blur(4px);
          // -webkit-backdrop-filter: blur(4px);

        // &::before {
        //   content: '';
        //   width: 100%;
        //   height: 100%;
        //   position: absolute;
        //   z-index: 1;
        //   filter: blur(4px);
        //   // -webkit-backdrop-filter: blur(4px);
        // }

        &-title {
          margin: 21px auto;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 548px;
          height: 60px;
          background-image: url("~@/assets/images/img_pc_box.png");
          background-size: 100% 100%;
          color: #ffffff;
          font-size: 24px;
          font-face: Aharoni;
          font-weight: 700;
          line-height: 0;
          letter-spacing: 0;
          paragraph-spacing: 3;
          // text-align: justify

          img {
            width: 48px;
            height: 48px;
            margin-right: 20px;
          }

          span {
            color: #f1ba0b;
          }
        }

        &-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 18px 0;

          .table-th {
            text-align: center;
            font-family: PingFangSC-SNaNpxibold;
            font-weight: 600;
            font-size: 18px;
            color: #F1BA0B;
            letter-spacing: 0;
            line-height: 26px;

            &:nth-child(1),
            &:nth-child(3) {
              width: 25%;
            }

            &:nth-child(2) {
              width: 50%;
            }
          }
        }

        .table-body {
          .table-tr {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 19px 0;

            .table-td {
              font-family: PingFangSC-Regular;
              font-size: 16px;
              color: #FFFFFF;
              letter-spacing: 0;
              text-align: center;

              &:nth-child(1),
              &:nth-child(3) {
                width: 25%;
              }

              &:nth-child(2) {
                width: 50%;
              }
            }

            &:nth-child(even) {
              background: rgba(255, 225, 102, 0.24);
            }

            &:nth-child(odd) {
              background: rgba($color: #FFFFFF, $alpha: 0.1);
            }
          }
        }
      }
    }

    &-two {
      margin: 48px 0;
      background: #ffffff1a;
      border: 1px solid #ffffff80;
      border-radius: 24px;
      padding: 66px 60px 41px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      backdrop-filter: blur(4px);

      img {
        width: 360px;
        height: 367px;
      }

      .about-text {
        margin-left: 60px;
        padding: 53px 60px 29px;
        background: #ffe1660d;

        &-title {
          display: flex;
          align-items: center;
          padding-left: 18px;
          width: 360px;
          height: 60px;
          background-image: url("~@/assets/images/img_pc_box.png");
          background-size: 100% 100%;
          color: #ffffff;
          font-size: 24px;
          font-face: Aharoni;
          font-weight: 700;
          line-height: 0;
          letter-spacing: 0;
          paragraph-spacing: 3;
          // text-align: justify

          span {
            color: #f1ba0b;
          }
        }

        &-content {
          margin-top: 24px;
          font-family: PingFangSC-SNaNpxibold;
          font-weight: 600;
          font-size: 14px;
          color: #F1BA0B;
          letter-spacing: 0;
          // text-align: justify

          p {
            &:nth-child(2) {
              margin: 30px 0;
            }
          }
        }
      }
    }

    &-three {
      background: #ffffff1a;
      border: 1px solid #ffffff80;
      border-radius: 24px;
      padding: 47px 60px 58px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      backdrop-filter: blur(4px);

      &-title {
        width: 360px;
        height: 108px;
        background-image: url("~@/assets/images/pc/img_pc_line.png");
        background-size: 100% 100%;
        padding-top: 28px;
        text-align: center;
        color: #ffffff;
        font-size: 24px;
        font-face: Aharoni;
        font-weight: 700;
        line-height: 0;
        letter-spacing: 0;
        paragraph-spacing: 3;

        span {
          color: #f1ba0b;
        }
      }

      &-content {
        margin-top: 36px;
        text-align: center;

        p {
          color: #ffffff;
          font-size: 14px;
          font-face: PingFangSC;
          font-weight: 600;
          line-height: 25px;
          letter-spacing: 0;
          paragraph-spacing: 3;

          &:first-child {
            margin-bottom: 30px;
          }

          &:not(:first-child) {
            color: #f1ba0b;
          }

          a {
            text-decoration: underline;
            color: #f1ba0b;
          }
        }
      }
    }
  }
}

@media (max-width: 1509px) {
  .container {
    .header {
      padding: 56px 47px;
      height: auto;

      .banner {
        &-text {
          &-title {
            width: 100%;
            height: auto;
          }
        }
      }
    }

    .content {
      padding: 13px 47px 50px;

      &-two {
        padding: 26px 20px 21px;

        img {
          width: 200px;
          height: 203px;
        }

        .about-text {
          margin-left: 30px;
          padding: 23px 30px 29px;

          &-title {
            width: 200px;
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .container {
    .header {
      padding: 35px 29px 17px;
      background-image: url("~@/assets/images/H5/img_h5_bg.png");
      // background-size: 100% 100%;
      .banner {
        margin-top: 32px;

        &-text {
          &-title {
            width: 60%;
            font-size: 24px;
            height: auto;
          }

          &-label {
            margin: 12px 0 24px;
            width: 60%;
            height: auto;
            font-size: 15px;
          }

          &-content {
            &-item {
              width: auto;
              display: flex;
              align-items: center;
              color: #F1BA0B;
              
              .span {
                height: 49px;
                font-family: PingFangSC-Regular;
                font-size: 24px;
                // letter-spacing: 0;
              }

              &:last-child {
                margin-top: 20px
              }
            }
          }
        }
      }
    }

    .content {
      padding: 13px 24px 35px;

      &-one {
        padding-top: 35px;

        .table {
          border-radius: 30px;
          padding: 0 12px 28px;

          &-title {
            width: 80%;
            font-size: 16px;

            img {
              width: 24px;
              height: 24px;
              margin-right: 10px;
            }

            span {
              color: #f1ba0b;
            }
          }

          &-header {
            .table-th {
              font-size: 16px;
              line-height: 20px;

              &:nth-child(1),
              &:nth-child(3) {
                width: 30%;
              }

              &:nth-child(2) {
                width: 44%;
              }
            }
          }

          .table-body {
            .table-tr {
              .table-td {
                font-size: 12px;

                &:nth-child(1),
                &:nth-child(3) {
                  width: 30%;
                }

                &:nth-child(2) {
                  width: 44%;
                }
              }
            }
          }
        }
      }

      &-two {
        margin: 32px 0;
        border-radius: 24px;
        padding: 22px 12px 13px;
        // display: flex;
        // justify-content: space-between;
        // align-items: center;

        img {
          width: 0;
          height: 0;
        }

        .about-text {
          background-image: url("~@/assets/images/H5/img_h5_about.png");
          background-size: 100% 100px;
          background-repeat: no-repeat;
          margin-left: 0;
          padding: 12px;

          &-title {
            margin: 0 auto;
            display: flex;
            justify-content: center;
            // align-items: center;
            padding-left: 0;
            width: 70%;
            height: 108px;
            background-image: url("~@/assets/images/pc/img_pc_line.png");
            background-size: 100% 100%;
            font-size: 24px;
          }

          &-content {
            margin-top: 11px;

            p {
              &:nth-child(2) {
                margin: 18px 0;
              }
            }
          }
        }
      }

      &-three {
        border-radius: 30px;
        padding: 20px 12px 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &-title {
          width: 100%;
          height: 108px;
          background-image: url("~@/assets/images/pc/img_pc_line.png");
          background-size: 100% 100%;
          padding-top: 28px;
          text-align: center;
          color: #ffffff;
          font-size: 24px;
        }

        &-content {
          margin-top: 15px;
          text-align: left;

          p {
            &:first-child {
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
}

// @media (max-width: 500px) {
// }</style>
