import { createApp } from 'vue'
import App from './App.vue'
import installElementPlus from './plugins/element'
// import './assets/css/xy.css'
import './assets/css/tailwind.css'

// import router from './router'
// import store from './store'

// import api from '../api/axios'
// Vue.prototype.$api = api

const app = createApp(App)
// app.use(router)
// app.use(store)

installElementPlus(app)
app.mount('#app')